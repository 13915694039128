import { type SelectOption } from '@shared/ui-components';

export const getDefaultCountryOption = ({
  countryOptions,
  userCountry,
}: {
  countryOptions: Array<SelectOption<string>>;
  userCountry: { countryCode: string; countryName: string };
}) => {
  return (
    countryOptions.find(
      (option) =>
        // strict match by country code and name
        (option?.value === userCountry.countryCode && option?.label === userCountry.countryName) ||
        // loose match by country code
        option?.value === userCountry.countryCode
      // last resort - first country in the list
    ) || countryOptions[0]
  );
};
