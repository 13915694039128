import { ScrollableContainer, Table } from '../../../../components';
import { type MembershipRequirementsPerYear } from '../../../../utils';
import { type MembershipRequirementBlock } from '../../../../utils/data/graphql/_generated/gql-generated';
import { MembershipLevel } from '../../../../utils/enums/membership';
import { useDataFieldRenderer } from '../../utils';
import { type MembershipRequirementsBlockWithFallbacks } from '../../utils/helpers/addFallbacksToMembershipRequirementsBlock';
import { MembershipLevelLabel } from '../membership-level-label/membership-level-label';

import styles from './membership-dues.module.scss';

type MembershipDuesProps = {
  blockData: MembershipRequirementsBlockWithFallbacks;
  countryYearData?: MembershipRequirementsPerYear;
  isFetching: boolean;
  yearBlockData?: MembershipRequirementBlock;
};

export const MembershipDues = ({
  blockData,
  countryYearData,
  isFetching,
  yearBlockData,
}: MembershipDuesProps) => {
  const { renderDataField } = useDataFieldRenderer({
    basedOnSelectedCountry: false,
    countryYearData,
    isFetching,
  });

  return (
    <section
      className={styles.wrapper}
      {...(blockData?.MembershipDuesAnchorLink && {
        id: blockData.MembershipDuesAnchorLink,
      })}
    >
      <h2 className={styles['wrapper__label']}>{blockData.MembershipDuesStepLabel}</h2>
      <p className={styles['wrapper__description']}>{blockData.MembershipDuesDescription}</p>
      <ScrollableContainer>
        <Table className={styles['membership-dues-table']} tabIndex={0}>
          <thead>
            <tr>
              <th scope="column">
                <MembershipLevelLabel
                  label={blockData.MembershipTableMemberHeading}
                  membershipLevel={MembershipLevel.MDRT}
                />
              </th>
              <th scope="column">
                <MembershipLevelLabel
                  label={blockData.MembershipTableCoTHeading}
                  membershipLevel={MembershipLevel.COT}
                />
              </th>
              <th scope="column">
                <MembershipLevelLabel
                  label={blockData.MembershipTableToTHeading}
                  membershipLevel={MembershipLevel.TOT}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{renderDataField(yearBlockData?.MemberDues ?? undefined)}</td>
              <td>{renderDataField(yearBlockData?.CoTDues ?? undefined)}</td>
              <td>{renderDataField(yearBlockData?.ToTDues ?? undefined)}</td>
            </tr>
          </tbody>
        </Table>
      </ScrollableContainer>
    </section>
  );
};
