import { auth } from '@shared/misc';

import { getCustomerProfile } from '../../../../utils/data/get-customer-profile';
import { getIpBasedLocation } from '../../../../utils/data/get-ip-based-location';

import { lookupCountryCodeByCountryId } from './lookupCountryCodeByCountryId';

type Country = { countryCode: string; countryName: string };

const DEFAULT_COUNTRY: Country = { countryCode: 'US', countryName: 'United States' };

const isValidCountry = (
  countryCandidate: Record<'countryName' | 'countryCode', Nullable<string> | undefined> | null
): countryCandidate is Country =>
  Boolean(countryCandidate?.countryName && countryCandidate?.countryCode);

const getAuthorizedUserCountry = async () => {
  const session = await auth();
  const personId = session?.personId;

  if (!personId) {
    return null;
  }

  const customerProfileData = await getCustomerProfile({ personId });
  return {
    countryCode:
      customerProfileData.countryCode ||
      (await lookupCountryCodeByCountryId(customerProfileData.countryId)),
    countryName: customerProfileData.country,
  };
};

export const getUserCountry = async (): Promise<{
  countryCode: string;
  countryName: string;
}> => {
  const countryFromUserProfile = await getAuthorizedUserCountry();

  if (isValidCountry(countryFromUserProfile)) {
    return countryFromUserProfile;
  }

  const countryByIp = await getIpBasedLocation();

  if (isValidCountry(countryByIp)) {
    return countryByIp;
  }

  return DEFAULT_COUNTRY;
};
