import Skeleton from 'react-loading-skeleton';

import { RenderHTML } from '@shared/ui-components';

import { type MembershipRequirementsPerYear } from '../../../../utils';
import {
  type MembershipRequirementBlockLinkBlock,
  type MembershipRequirementBlock,
} from '../../../../utils/data/graphql/_generated/gql-generated';
import { LinkBlock } from '../../../link-block';
import { type MembershipRequirementsBlockWithFallbacks } from '../../utils/helpers/addFallbacksToMembershipRequirementsBlock';

import styles from './certifying-letters.module.scss';

type CertifyingLettersProps = {
  blockData: MembershipRequirementsBlockWithFallbacks;
  countryYearData?: MembershipRequirementsPerYear;
  isFetching: boolean;
  yearBlockData?: MembershipRequirementBlock;
};

export const CertifyingLetters = ({
  blockData,
  countryYearData,
  isFetching,
  yearBlockData,
}: CertifyingLettersProps) => {
  const renderLink = (linkBlockData?: MembershipRequirementBlockLinkBlock | null) => {
    if (isFetching) {
      return <Skeleton className={styles['wrapper__link-skeleton']} />;
    }

    if (!linkBlockData?.LinkToContent) {
      return undefined;
    }

    return <LinkBlock blockData={linkBlockData} />;
  };

  return (
    <section
      className={styles.wrapper}
      {...(blockData?.CertifyingLettersAnchorLink && {
        id: blockData.CertifyingLettersAnchorLink,
      })}
    >
      <h2 className={styles['wrapper__label']}>{blockData?.CertifyingLettersStepLabel}</h2>
      <RenderHTML html={blockData?.CertifyingLettersDescription} />
      <div className={styles['wrapper__links']}>
        {countryYearData?.unlimitedProductionNotRequired ? (
          <>
            {renderLink(yearBlockData?.NonCorePremiumAndCommissionCertifyingLetters)}
            {renderLink(yearBlockData?.NonCoreIncomeCertifyingLetters)}
          </>
        ) : (
          <>
            {renderLink(yearBlockData?.PremiumAndCommissionCertifyingLetters)}
            {renderLink(yearBlockData?.IncomeCertifyingLetters)}
          </>
        )}
      </div>
    </section>
  );
};
