'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import { KeyboardKey } from '@shared/utils';

import { useGlobalCmsContentContext } from '../../components';

import { useLanguageSwitcher } from './use-language-switcher';

const isExternalURL = (url: string) => {
  const temporary = document.createElement('a');
  temporary.href = url;
  return temporary.host !== window.location.host;
};

const isAndroidDevice = (): boolean => {
  // eslint-disable-next-line require-unicode-regexp
  return /android/i.test(navigator.userAgent);
};

export const useConfirmNavigation = (
  isConfirm: boolean,
  backUrl: string,
  options?: { abortRequest?: () => void; onConfirm?: (dataKey: string) => void }
) => {
  const router = useRouter();
  const { onLanguageChange } = useLanguageSwitcher();
  const { cmsContent } = useGlobalCmsContentContext();
  const { abortRequest, onConfirm } = options || {};

  useEffect(() => {
    const tabs = Array.from(document.querySelectorAll('[role="tab"]')) as HTMLElement[];
    let isTabActivated = false;
    const confirmMessage = cmsContent.leavingConfirmMessage;
    let lastClickTime = 0;
    const debounceTime = 300;

    /**
     * Used to prevent navigation when use `back` browser buttons.
     */
    const handlePopState = (event: PopStateEvent) => {
      if (isConfirm && !isTabActivated) {
        event.preventDefault();
        window.history.pushState(null, document.title, window.location.href);

        // eslint-disable-next-line no-alert
        if (!window.confirm(confirmMessage)) {
          window.history.pushState(null, document.title, backUrl);
          abortRequest?.();
        }
      }
    };

    /**
     * Used to prevent navigation when reload page or navigate to another page, in different origin.
     */
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isConfirm) {
        event.preventDefault();
        event.stopPropagation();
        abortRequest?.();
      }
    };

    /**
     * Used to prevent navigation when user clicks on `<a role="tab" />`/`<button role="tab" />`.
     */
    const handlePointerDown = (event: Event) => {
      if (isConfirm) {
        const currentTarget = event.currentTarget as HTMLElement;
        const dataKeyAttribute = currentTarget.getAttribute('data-key') || '';

        event.stopPropagation();
        isTabActivated = true;

        // eslint-disable-next-line no-alert
        if (window.confirm(confirmMessage)) {
          if (!onConfirm) {
            window.location.hash = dataKeyAttribute;
            window.dispatchEvent(new HashChangeEvent('hashchange'));
          }

          onConfirm?.(dataKeyAttribute);
          abortRequest?.();
        }
      }
    };

    /**
     * Used to prevent navigation when user navigates through tab navigation with keyboard
     */
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isConfirm) {
        const currentTarget = event.currentTarget as HTMLElement;
        const currentTabIndex = tabs.indexOf(currentTarget);
        let dataKeyAttribute = '';
        let nextTabIndex = -1;

        if (event.key === KeyboardKey.ArrowDown || event.key === KeyboardKey.ArrowRight) {
          nextTabIndex = (currentTabIndex + 1) % tabs.length;
        } else if (event.key === KeyboardKey.ArrowUp || event.key === KeyboardKey.ArrowLeft) {
          nextTabIndex = (currentTabIndex - 1 + tabs.length) % tabs.length;
        }

        event.stopPropagation();
        isTabActivated = true;

        if (nextTabIndex === -1) return;

        // eslint-disable-next-line no-alert
        if (window.confirm(confirmMessage)) {
          if (nextTabIndex !== -1) {
            const nextTab = tabs[nextTabIndex];
            nextTab.setAttribute('tabindex', '0');
            nextTab.focus();
            dataKeyAttribute = nextTab.getAttribute('data-key') || '';
          }

          if (!onConfirm) {
            window.location.hash = dataKeyAttribute;
            window.dispatchEvent(new HashChangeEvent('hashchange'));
          }

          onConfirm?.(dataKeyAttribute);
          abortRequest?.();
        }
      }
    };

    /**
     * Used to prevent navigation when user clicks on the same origin links or wants to change language
     */
    const handleClick = (event: MouseEvent) => {
      if (isConfirm) {
        const languageSwitcherAttribute = 'data-language-switcher';
        const now = Date.now();
        if (now - lastClickTime < debounceTime) return;
        lastClickTime = now;

        const target = event.target as HTMLElement;
        let link = null;

        if (target.tagName === 'A' && !target.hasAttribute('role')) {
          link = target;
        } else {
          link = target.closest('a[href]');
        }

        const url: string | null = link && link instanceof HTMLAnchorElement ? link.href : null;

        if (url && url !== window.location.href && !isExternalURL(url)) {
          window.history.pushState(null, document.title, window.location.href);

          // eslint-disable-next-line no-alert
          if (window.confirm(confirmMessage)) {
            router.push(url);
            abortRequest?.();
          }
        }

        if (target.hasAttribute(languageSwitcherAttribute)) {
          window.history.pushState(null, document.title, window.location.href);

          // eslint-disable-next-line no-alert
          if (window.confirm(confirmMessage)) {
            onLanguageChange(target.getAttribute(languageSwitcherAttribute) as string);
          }
        }
      }
    };

    for (const tab of tabs) {
      if (isAndroidDevice()) {
        tab.addEventListener('touchstart', handlePointerDown);
      } else {
        tab.addEventListener('pointerdown', handlePointerDown);
      }

      tab.addEventListener('keydown', handleKeyDown);
    }

    document.addEventListener('click', handleClick);
    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      for (const tab of tabs) {
        if (isAndroidDevice()) {
          tab.removeEventListener('touchstart', handlePointerDown);
        } else {
          tab.removeEventListener('pointerdown', handlePointerDown);
        }

        tab.removeEventListener('keydown', handleKeyDown);
      }

      document.removeEventListener('click', handleClick);
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backUrl, isConfirm, router]);
};
