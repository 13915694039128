import { type ContactsBlock as ContactsBlockData } from '../../../../utils/data/graphql/_generated/gql-generated';
import { type MembershipRequirementsBlockWithFallbacks } from '../../utils/helpers/addFallbacksToMembershipRequirementsBlock';

import styles from './contacts-block.module.scss';

type ContactsBlockProps = {
  blockData: MembershipRequirementsBlockWithFallbacks;
};

export const ContactsBlock = ({ blockData }: ContactsBlockProps) => {
  const contacts = blockData.Contacts as ContactsBlockData[];

  return (
    <div className={styles['contacts']}>
      {contacts.map((contact) => {
        const {
          Email: email,
          FaxNumber: fax,
          PhoneNumber: phone,
          RegionTitle: title,
        } = contact.ContentLink?.Expanded as ContactsBlockData;

        return (
          <div className={styles['contacts__contact-block']} key={title}>
            <div className={styles['contacts__region-title']}>{title}</div>
            {phone && (
              <div className={styles['contacts__contact-data']}>
                Phone: <a href={`tel:${phone}`}>{phone}</a>
              </div>
            )}
            {fax && (
              <div className={styles['contacts__contact-data']}>
                Fax: <a href={`tel:${fax}`}>{fax}</a>
              </div>
            )}
            {email && (
              <div className={styles['contacts__contact-data']}>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
