import { useFormatter } from 'next-intl';

import { type MembershipRequirementDimensions } from '../../../../utils';

export const useMembershipRequirementsOptions = (dimensions: MembershipRequirementDimensions) => {
  const formatter = useFormatter();

  const countryOptions =
    dimensions?.countries?.map(({ code, name }) => ({
      label: name,
      value: code,
    })) ?? [];

  const yearOptions = dimensions?.years
    ?.sort((yearA, yearB) => yearA - yearB)
    .map((year) => ({
      label: formatter.dateTime(Date.UTC(year), {
        year: 'numeric',
      }),
      value: year,
    }));

  return { countryOptions, yearOptions };
};
