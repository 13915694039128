import { useFormatter } from 'next-intl';
import Skeleton from 'react-loading-skeleton';

import { type MembershipRequirementsPerYear } from '../../../../utils';

export const useDataFieldRenderer = ({
  basedOnSelectedCountry = true,
  countryYearData,
  isFetching,
}: {
  basedOnSelectedCountry?: boolean;
  countryYearData?: MembershipRequirementsPerYear;
  isFetching: boolean;
}) => {
  const format = useFormatter();

  const renderDataField = (value: number | string | undefined) => {
    if (isFetching) {
      return <Skeleton />;
    }

    if (!value) {
      return undefined;
    }

    if (basedOnSelectedCountry) {
      if (!countryYearData?.currencyCode) {
        return undefined;
      }

      return `${format.number(Number(value))} ${countryYearData?.currencyCode}`;
    }

    return `${format.number(Number(value))} USD`;
  };

  return {
    renderDataField,
  };
};
