import { ScrollableContainer, Table } from '../../../../components';
import { type MembershipRequirementsPerYear } from '../../../../utils';
import { type MembershipRequirementBlock } from '../../../../utils/data/graphql/_generated/gql-generated';
import { MembershipLevel } from '../../../../utils/enums/membership';
import { useDataFieldRenderer } from '../../utils';
import { type MembershipRequirementsBlockWithFallbacks } from '../../utils/helpers/addFallbacksToMembershipRequirementsBlock';
import { AdditionalRequirements } from '../additional-requirements';
import { ContactsBlock } from '../contacts-block/contacts-block';
import { MembershipLevelLabel } from '../membership-level-label/membership-level-label';

import styles from './production-requirements.module.scss';

type ProductionRequirementsProps = {
  blockData: MembershipRequirementsBlockWithFallbacks;
  countryYearData?: MembershipRequirementsPerYear;
  error: Error | null;
  isFetching: boolean;
  yearBlockData?: MembershipRequirementBlock;
};

export const ProductionRequirements = ({
  blockData,
  countryYearData,
  error,
  isFetching,
  yearBlockData,
}: ProductionRequirementsProps) => {
  const { renderDataField } = useDataFieldRenderer({
    countryYearData,
    isFetching,
  });

  const noResultsMessage = () => {
    if (!countryYearData) {
      return blockData.NoRequirementsLabel ?? '';
    } else if (error) {
      return blockData.ProductionApiErrorLabel ?? '';
    }

    return '';
  };

  return (
    <>
      {!isFetching && (!countryYearData || error) ? (
        <div className={styles['note-wrapper']}>
          <div className={styles['error-message']}>{noResultsMessage()}</div>
          <ContactsBlock blockData={blockData} />
        </div>
      ) : (
        <ScrollableContainer className={styles.container}>
          <Table className={styles['production-table']} tabIndex={0}>
            <thead>
              <tr>
                <th className={styles['production-table--sticky']} scope="col">
                  {blockData.MembershipTableName}
                </th>
                <th scope="col">
                  <MembershipLevelLabel
                    label={blockData.MembershipTableMemberHeading}
                    membershipLevel={MembershipLevel.MDRT}
                  />
                </th>
                <th scope="col">
                  <MembershipLevelLabel
                    label={blockData.MembershipTableCoTHeading}
                    membershipLevel={MembershipLevel.COT}
                  />
                </th>
                <th scope="col">
                  <MembershipLevelLabel
                    label={blockData.MembershipTableToTHeading}
                    membershipLevel={MembershipLevel.TOT}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={styles['production-table--sticky']} scope="row">
                  {blockData.MembershipTableCommissionRowHeading}
                </th>
                <td>{renderDataField(countryYearData?.['mdrtCommission'])}</td>
                <td>{renderDataField(countryYearData?.['coTCommission'])}</td>
                <td>{renderDataField(countryYearData?.['toTCommission'])}</td>
              </tr>
              <tr>
                <th className={styles['production-table--sticky']} scope="row">
                  {blockData.MembershipTableIncomeRowHeading}
                </th>
                <td>{renderDataField(countryYearData?.['mdrtIncome'])}</td>
                <td>{renderDataField(countryYearData?.['coTIncome'])}</td>
                <td>{renderDataField(countryYearData?.['toTIncome'])}</td>
              </tr>
              <tr>
                <th className={styles['production-table--sticky']} scope="row">
                  {blockData.MembershipTablePremiumRowHeading}
                </th>
                <td>{renderDataField(countryYearData?.['mdrtPremium'])}</td>
                <td>{renderDataField(countryYearData?.['coTPremium'])}</td>
                <td>{renderDataField(countryYearData?.['toTPremium'])}</td>
              </tr>
            </tbody>
          </Table>
        </ScrollableContainer>
      )}
      <AdditionalRequirements
        blockData={blockData}
        countryYearData={countryYearData}
        isFetching={isFetching}
        yearBlockData={yearBlockData}
      />
    </>
  );
};
